import httpUtil from "@/utils/httpUtil";

/**获取分销提成比例 */
export const commissionCount = (params,type) => httpUtil.post("/distributor/commissionCount", params,type);

/**分销用户排名查询 */
export const userRank =  params => httpUtil.post("/distributor/userRank", params);

/**分销商品查询 */
export const goodRank =  params => httpUtil.post("/distributor/goodRank", params);

/**人员申请 */
export const queryDistributorApp = params => httpUtil.post("/distributor/queryDistributorApp", params);

/**分销订单 */
export const selectDistributorOrderList = params => httpUtil.get("/distributor/orderList", params);

/**到账流水 */
export const  selectCompanyTransferList = params => httpUtil.post("/distribution/selectCompanyTransferList", params);

/**更爱结算状态 */
export const updateCompanyTransferState = params => httpUtil.post("/distribution/updateCompanyTransferState", params);

/**取消资格 */
export const cancelDistributorApp = params => httpUtil.post("/distributor/cancelDistributorApp", params);

/**通过 */
export const verifyDistributorApp =  params => httpUtil.post("/distributor/verifyDistributorApp", params);

/**设置自动审核 */
export const updateAuditSettings = (params, type) => httpUtil.post("/distributor/updateAuditSettings", params, type);

/**获取审核状态 */
export const queryAuditSettings = params => httpUtil.post("/distributor/queryAuditSettings", params);

/**获取分销等级 */

export const queryCompanyRoyalty = params => httpUtil.post("/distributor/queryCompanyRoyalty", params);

/**保存设置分销登记 */
export const updateCompanyRoyalty = (params, type) => httpUtil.post("/distributor/updateCompanyRoyalty", params, type);

/**分销商品占比 */
export const goodRankPercentage = params => httpUtil.post("/distributor/goodRankPercentage", params);

/**佣金支出统计 */
export const expenditureCount = params => httpUtil.post("/distributor/expenditureCount", params);
